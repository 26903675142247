import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from '@/store';
import { ROUTES } from '@/constants';
import { Err, WRONG_PASSWORD } from '@/services/const';

export function useErrorDetection() {
  const { auth } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    const { code, msg } = auth.err || {};
    if (code) {
      if (
        (code === Err.ErrLoginFailed && msg === WRONG_PASSWORD) ||
        code === Err.ErrInvalidJwt
      ) {
        auth.clearUser();
        navigate(ROUTES.LOGIN);
      } else {
        navigate(ROUTES.ERROR);
      }
    }
  }, [auth, auth.err, navigate]);
}
