import React from 'react';
import auth, { Auth } from './auth';
import order, { Order } from './order';

export type RootStore = {
  auth: Auth;
  order: Order;
};

export const store: RootStore = {
  auth,
  order
};

const StoreContext = React.createContext<RootStore>(store);

export const StoreProvider = StoreContext.Provider;

export const useStore = () => React.useContext(StoreContext);

export { auth, order };
