import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { BaseLayout } from '@/layouts';
import Loader from '@/views/Loader';
import { ROUTES } from '@/constants';

const NotFound = lazy(() => import('@/views/NotFound'));
const Home = lazy(() => import('@/views/Home'));
const Purchase = lazy(() => import('@/views/Purchase'));
const PurchaseCompleted = lazy(() => import('@/views/PurchaseCompleted'));
const Refund = lazy(() => import('@/views/Refund'));
const Receipt = lazy(() => import('@/views/Receipt'));
const Transaction = lazy(() => import('@/views/Transaction'));
const Login = lazy(() => import('@/views/Login'));
const Error = lazy(() => import('@/views/Error'));

const AppRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path={ROUTES.HOME} element={<BaseLayout />}>
          <Route index element={<Home />} />
          <Route path={ROUTES.LOGIN} element={<Login />} />
          <Route path={ROUTES.PURCHASE} element={<Purchase />} />
          <Route path={ROUTES.COMPLETED} element={<PurchaseCompleted />} />
          <Route path={ROUTES.PAYMENT} element={<Transaction />} />
          <Route path={ROUTES.REFUND} element={<Refund />} />
          <Route path={ROUTES.ERROR} element={<Error />} />
          <Route path={ROUTES.RECEIPT} element={<Receipt />} />
          <Route path="404" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
