export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  REFUND: '/refund',
  PURCHASE: '/purchase',
  COMPLETED: '/completed',
  ERROR: '/error',
  PAYMENT: '/payment/:orderId/:duration',
  RECEIPT: '/receipt'
} as const;
