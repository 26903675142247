import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Button } from '@/components';
import { useStore } from '@/store';
import { MemberStatus } from '@/services/const';
import { ROUTES } from '@/constants';
import { ICardProps } from './Card.types';

import styles from './card.module.css';

const Card: React.FC<ICardProps> = ({
  month,
  purchased,
  used,
  purchaseDate,
  validPeriod,
  price,
  title,
  footerMsg
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth } = useStore();

  const handleCardClick = useCallback(() => {
    const nextStepRoute = purchased ? ROUTES.REFUND : ROUTES.PURCHASE;
    navigate(nextStepRoute);
  }, [navigate, purchased]);

  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <label className={styles.cardTitle}>
          {title
            ? title
            : purchased && used
            ? t('views.common.usedProductTitle')
            : t('views.common.currentlyUsedProductStatus', { month })}
        </label>
      </div>
      <div className={styles.cardContent}>
        {purchased ? (
          <>
            <p className={styles.cardSubTitle}>
              {t('views.common.currentlyUsedProductDetail', { month })}
            </p>
            <p className={styles.cardSubTitleDateContent}>
              {t('views.purchase.purchaseDate', { purchaseDate })}
            </p>
            <p className={styles.cardSubTitleDateContent}>
              {t('views.purchase.effectiveDate', { validPeriod })}
            </p>
          </>
        ) : (
          <p className={styles.cardSubTitleUnpurchased}>
            {t('views.common.monthNotPurchasedYet', { month })}
          </p>
        )}
        {footerMsg && (
          <div className={styles.cardContentInfoLabel}>
            <label className={styles.cardContentInfoMsg}>{footerMsg}</label>
          </div>
        )}
        {price ? (
          <p className={styles.cardPriceAmount}>{price}</p>
        ) : (
          <div className={styles.cardContentFooter}>
            {!used && (
              <Button
                type="button"
                color="primary"
                className={styles.cardButton}
                onClick={handleCardClick}
                disabled={auth.memberStatusToken === MemberStatus.Withdrawal}
              >
                {purchased
                  ? t('views.purchase.refund')
                  : t('views.purchase.purchaseMonth', { month })}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(Card);
