import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cls from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Link, message } from '@/components';
import { useScrollLock } from '@/hooks/useScrollLock';
import { useFocusTrap } from '@/hooks/useFocusTrap';
import { useWindowEvent } from '@/hooks/useWindowEvent';
import { eventKey } from '@/constants/keyboard';
import { auth, order } from '@/store';
import logo from '@/assets/metro-logo.png';
import { User } from '@/assets';
import styles from './header.module.css';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [showAccount, setShowAccount] = useState(false);

  const handleClose = () => setOpen(false);
  const focusTrapRef = useFocusTrap(open);
  const CREDIT_CARD_PATH =
    import.meta.env.VITE_APP_CREDIT_PATH + '?access_token=';

  useScrollLock(open);

  useWindowEvent('keydown', event => {
    if (event.key === eventKey.Escape && open) {
      handleClose();
    }
  });

  const accountButton = useMemo(
    () => (
      <button
        className={styles.accountButton}
        onClick={() => setShowAccount(visible => !visible)}
      >
        <User className={styles.accountButtonIcon} />
        <label className={styles.accountButtonMsg}>
          {t('views.common.accountInfo')}
        </label>
      </button>
    ),
    [t]
  );

  const handleCreditCardStore = useCallback(async () => {
    try {
      const memberToken = await auth.getMemberToken();
      if (memberToken) {
        window.location.href = `${CREDIT_CARD_PATH}${memberToken}`;
      } else {
        message.error(t('views.common.creditCardManagementGetErrMsg'));
      }
    } catch {
      message.error(t('views.common.creditCardManagementGetErrMsg'));
    }
  }, [CREDIT_CARD_PATH, t]);

  const logout = useCallback(() => {
    auth.clearUser();
    order.clearOrders();
    navigate(`/login`);
    setOpen(false);
  }, [navigate]);

  return (
    <header className={styles.header}>
      <div className={styles.inner}>
        {auth.accessToken && (
          <button
            className={styles.menu}
            onClick={() => setOpen(open => !open)}
          >
            <span
              className={cls(styles.menuIcon, {
                [styles.open]: open
              })}
            ></span>
          </button>
        )}
        <Link to="/" className={styles.logo}>
          <img src={logo} alt="logo" width="232" />
        </Link>
        {auth.accessToken && (
          <div
            className={cls(styles.info, {
              [styles.open]: open
            })}
            ref={focusTrapRef}
          >
            {open && (
              <div>
                <button
                  className={styles.dropdownButton}
                  onClick={handleCreditCardStore}
                >
                  {t('views.common.creditCardManagement')}
                </button>
                <button className={styles.dropdownButton} onClick={logout}>
                  {t('views.common.signOut')}
                </button>
              </div>
            )}
            <Dropdown
              target={accountButton}
              visible={showAccount}
              sameWidth={false}
              onClose={() => setShowAccount(false)}
            >
              <Dropdown.Menu className={styles.dropdownMenu}>
                <Dropdown.Item>
                  <button
                    className={styles.dropdownButton}
                    onClick={handleCreditCardStore}
                  >
                    {t('views.common.creditCardManagement')}
                  </button>
                </Dropdown.Item>

                <Dropdown.Item>
                  <button className={styles.dropdownButton} onClick={logout}>
                    {t('views.common.signOut')}
                  </button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </div>
    </header>
  );
};

export default observer(Header);
