export const formatPrice = (price?: string | number) => {
  if (price !== undefined) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return '';
  }
};

export const isObject = (obj: any): boolean => {
  return obj !== null && typeof obj === 'object';
};

export type Alignment = 'start' | 'end';
export type Side = 'top' | 'right' | 'bottom' | 'left';
export type AlignedPlacement = `${Side}-${Alignment}`;
export type Placement = Side | AlignedPlacement;

const currentDate = new Date();

export const hasOwnProperty = (value: unknown, key: string): boolean =>
  Object.prototype.hasOwnProperty.call(value, key);
export const objectToString = Object.prototype.toString;
export const toTypeString = (value: unknown): string =>
  objectToString.call(value);

export const isMap = (val: unknown): val is Map<any, any> =>
  toTypeString(val) === '[object Map]';
export const isSet = (val: unknown): val is Set<any> =>
  toTypeString(val) === '[object Set]';
export const isDate = (val: unknown): val is Date =>
  toTypeString(val) === '[object Date]';
export const isRegExp = (val: unknown): val is RegExp =>
  toTypeString(val) === '[object RegExp]';
export const isFunction = (val: unknown): val is AnyFunction =>
  typeof val === 'function';
export const isString = (val: unknown): val is string =>
  typeof val === 'string';
export const isSymbol = (val: unknown): val is symbol =>
  typeof val === 'symbol';
export const isPlainObject = (val: unknown): val is object =>
  toTypeString(val) === '[object Object]';

export const sleep = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

export const flushPromises = () => sleep(0);

export function randomId() {
  return Math.random().toString(36).substring(2, 9);
}

export const addDays = (date: Date, days: number) => {
  const result = new Date(date);
  result.setDate(date.getDate() + days);
  return result;
};

export const currentMonth = currentDate.getMonth() + 1;
export const nextMonth = (currentMonth % 12) + 1;

export const DATE_REGEX =
  /^([12]\d{3}-)?(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;

export const URL_REGEX =
  /(https?|http):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%?=~_|]/g;

export function parseDate(date: string): Date {
  if (DATE_REGEX.test(date)) {
    if (date.length > 5) {
      const [year, month, day] = date.split('-');
      return new Date(Number(year), Number(month) - 1, Number(day));
    } else {
      const [month, day] = date.split('-');
      const currentYear = new Date().getFullYear();
      return new Date(currentYear, Number(month) - 1, Number(day));
    }
  } else {
    return new Date();
  }
}

export const convertDateToString = (dateObj: Date): string => {
  const month =
    dateObj.getMonth() < 9
      ? `0${dateObj.getMonth() + 1}`
      : `${dateObj.getMonth() + 1}`;
  const date =
    dateObj.getDate() < 10 ? `0${dateObj.getDate()}` : dateObj.getDate();
  return `${dateObj.getFullYear()}-${month}-${date}`;
};

export const formatter = new Intl.NumberFormat('ja-JP', {
  style: 'currency',
  currency: 'JPY'
});

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const ACCOUNT_REGEX = /^[A-Za-z\d]{10}$/;

export const PASSWORD_REGEX = /^[A-Za-z\d]{4,12}$/;

export const clamp = (value: number, min: number, max: number): number => {
  return Math.min(Math.max(value, min), max);
};

function looseCompareArrays(a: any[], b: any[]) {
  if (a.length !== b.length) return false;
  let equal = true;
  for (let i = 0; equal && i < a.length; i++) {
    equal = looseEqual(a[i], b[i]);
  }
  return equal;
}

export function looseEqual(a: any, b: any): boolean {
  if (a === b) return true;
  let aValidType = isDate(a);
  let bValidType = isDate(b);
  if (aValidType || bValidType) {
    return aValidType && bValidType ? a.getTime() === b.getTime() : false;
  }
  aValidType = isSymbol(a);
  bValidType = isSymbol(b);
  if (aValidType || bValidType) {
    return a === b;
  }
  aValidType = Array.isArray(a);
  bValidType = Array.isArray(b);
  if (aValidType || bValidType) {
    return aValidType && bValidType ? looseCompareArrays(a, b) : false;
  }
  aValidType = isObject(a);
  bValidType = isObject(b);
  if (aValidType || bValidType) {
    if (!aValidType || !bValidType) {
      return false;
    }
    const aKeysCount = Object.keys(a).length;
    const bKeysCount = Object.keys(b).length;
    if (aKeysCount !== bKeysCount) {
      return false;
    }
    for (const key in a) {
      const aHasKey = hasOwnProperty(a, key);
      const bHasKey = hasOwnProperty(b, key);
      if (
        (aHasKey && !bHasKey) ||
        (!aHasKey && bHasKey) ||
        !looseEqual(a[key], b[key])
      ) {
        return false;
      }
    }
  }
  return String(a) === String(b);
}

export function deepClone<T>(val: T): T {
  if (!isObject(val)) {
    return val;
  }
  if (Array.isArray(val)) {
    return val.map(deepClone) as typeof val;
  }
  if (isDate(val)) {
    return new Date(val) as typeof val;
  }
  if (isMap(val)) {
    return new Map(val) as typeof val;
  }
  if (isSet(val)) {
    return new Set(val) as typeof val;
  }
  if (isRegExp(val)) {
    return new RegExp(val.source, val.flags) as typeof val;
  }
  return Object.keys(val as object).reduce((acc, key) => {
    acc[key as keyof T] = deepClone(val[key as keyof T]);
    return acc;
  }, {} as T);
}

export const base64ToBlob = (base64: string, mime: string) => {
  const bin = atob(base64.replace(/^.*,/, ''));
  const buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }
  const blob = new Blob([buffer.buffer], { type: mime });
  return blob;
};

export const downloadCSV = (csvBase64: string, fileName: string) => {
  const blob = base64ToBlob(csvBase64, 'application/pdf');
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
