import { action, computed, makeObservable, observable } from 'mobx';
import paymentApi from '@/services/endpoints/payment';
import { MemberStatus, Common } from '@/services/const';
import { ROUTES } from '@/constants';

const storage = sessionStorage;
const ACCESS_TOKEN = 'access_token';
const MEMBER_STATUS_TOKEN = 'member_status_token';
const EMAIL = 'email';
export class Auth {
  @observable accessToken = storage.getItem(ACCESS_TOKEN) || '';
  @observable memberStatusToken = storage.getItem(MEMBER_STATUS_TOKEN) || '';
  @observable email = storage.getItem(EMAIL) || '';
  @observable err: Common | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  clearUser = () => {
    try {
      storage.removeItem(ACCESS_TOKEN);
      storage.removeItem(MEMBER_STATUS_TOKEN);
      storage.removeItem(EMAIL);
    } finally {
      this.accessToken = '';
      this.memberStatusToken = '';
      this.email = '';
    }
  };

  @action
  setAccessToken = (token: string) => {
    try {
      storage.setItem(ACCESS_TOKEN, token);
    } finally {
      this.accessToken = token;
    }
  };

  @action
  setMemberState = (status: MemberStatus) => {
    try {
      storage.setItem(MEMBER_STATUS_TOKEN, status);
    } finally {
      this.memberStatusToken = status;
    }
  };

  @action
  setEmail = (email: string) => {
    if (this.loggedIn && this.memberStatusToken === MemberStatus.Admission) {
      try {
        storage.setItem(EMAIL, email);
      } finally {
        this.email = email;
      }
    }
  };

  @action
  setErr = (err: Common) => {
    this.err = err;
  };

  @action
  clearErrorHandler = () => {
    this.err = null;
  };

  @action
  getMemberToken = async () => {
    if (this.accessToken) {
      const memberToken = await paymentApi.getMemberToken({});
      return memberToken.accessToken;
    }
  };

  @action
  getReturnedPath(): string {
    if (this.err?.code) {
      this.clearUser();
      this.clearErrorHandler();
      return ROUTES.LOGIN;
    }
    return ROUTES.HOME;
  }

  @computed
  get loggedIn(): boolean {
    return !!this.accessToken && !!this.memberStatusToken;
  }
}

export default new Auth();
