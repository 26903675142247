import { Metrohoudai } from '@linktivity/link-connect';
import { auth } from '@/store';

const BASE_PATH = import.meta.env.VITE_APP_BASE_PATH;

const config = new Metrohoudai.Configuration({
  basePath: BASE_PATH,
  middleware: [
    {
      pre: async context => {
        const requestHeaders: HeadersInit = new Headers({
          'Content-Type': 'application/json'
        });
        requestHeaders.append('Authorization', auth.accessToken);
        context.init.headers = requestHeaders;
      },
      post: async context => {
        if (!context.response.ok) {
          const data = await context.response.json();
          auth.setErr(data.common);
          if (context.response.status === 401) {
            sessionStorage.clear();
          }
        }
      }
    }
  ]
});

export default config;
