import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Header from '../Header';
import { useErrorDetection } from '@/hooks';
import styles from './base.module.css';

const BaseLayout: FC = () => {
  useErrorDetection();
  return (
    <div className={styles.base}>
      <Header />
      <Outlet />
    </div>
  );
};

export default observer(BaseLayout);
