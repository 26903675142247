import { Metrohoudai } from '@linktivity/link-connect';

const MemberStatus = Metrohoudai.MemberStatus;
type MemberStatus = Metrohoudai.MemberStatus;

const Err = Metrohoudai.Err;
type Err = Metrohoudai.Err;

type Common = Metrohoudai.Common;

const WRONG_PASSWORD = 'Wrong password';

export { MemberStatus, Err, WRONG_PASSWORD };
export type { Common };
