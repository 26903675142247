export default {
  views: {
    login: {
      id: 'お客様番号',
      password: 'WEBパスワード',
      signIn: 'ログイン',
      validationRequired: '{{name}}を入力してください',
      validationMsg: '有効な{{name}}を入力してください',
      reLoginMsg: '再ログインしてください',
      ErrLoginFailed: 'パスワードが間違っています。'
    },
    common: {
      accountInfo: 'アカウント情報',
      confirm: '確定',
      back: '戻る',
      signOut: 'サインアウト',
      creditCardManagement: 'クレジットカード管理',
      creditCardManagementGetErrMsg:
        'クレジットカード管理の読み込み中に問題が発生しました',
      usedProductTitle: '利用中の休日メトロ放題',
      currentlyUsedProductStatus:
        '{{month}}月分の「休日メトロ放題」お申込み状況',
      currentlyUsedProductDetail: '{{month}}月分の休日メトロ放題詳細',
      purchsePageTitle: `下記、内容での購入となります。
        問題なければ、確定からお支払いにお進みください。\n
        なお、ご利用の前に必ず「メトポＷＥＢサイト」に記載の注意事項をよくご確認ください。`,
      monthNotPurchasedYet:
        '{{month}}月分の「休日メトロ放題」のお申込みはございません。',
      errorTitle: 'エラーが発生しました',
      backToMain: 'メインメニュー',
      emailPlaceholder: 'メールアドレスを入力してください',
      clientMsg: `＜ご購入は、翌月分のみとなります。＞
      当月分や、翌々月分のご購入はいただけませんのご了承ください。`,
      cancelFeeMsg: `*キャンセル手数料はかかりません`,
      cancelEmailMsg:
        'お申込み当日にキャンセルされた場合はキャンセル通知が送信されませんのでご了承ください。',
      invoice: '領収書が必要な方はこちら',
      invoiceDetail: `ご入用の場合は必要事項（メトポのお客様番号、申し込み日、宛名）を明記の上、
        <helpcenter@linktivity.co.jp>宛てにご連絡ください。`
    },
    purchase: {
      purchaseContentConfirm: '購入内容確認',
      refundContentConfirm: 'キャンセル内容確認',
      refundPromptMsg: `内容をご確認の上、キャンセルする場合は確定を、中止する場合は戻るを押してください。`,
      purchaseMonth: '{{month}}月分購入',
      purchaseNotice: `決済時は、途中で回線を切断したりブラウザを閉じないようにしてください。\n決済が未了となる場合がございます。`,
      purchasePrice: '支払い金額: {{price}}円',
      purchaseCompleted: '購入完了',
      purchaseCompletedPromptMsg: `ご利用ありがとうございます。購入が完了しました。\n
        メインメニューで購入内容をご確認ください。`,
      refund: 'お申込みをキャンセルする',
      refundProcessing: 'キャンセル処理中',
      refundPrice: '払戻し金額: {{price}}円',
      refundCompleted: 'キャンセル完了',
      refundEmptyErrorMsg: 'キャンセルできる内容がございません。',
      refundCompletedPromptMsg: `キャンセルが完了しました。
        再度、購入する場合はメインメニューより購入手続きをお願いします。\n
        なお、返金日につきましては、
        各カード会社の定めるところにより異なります。
        ご利用の各クレジットカード会社へお問い合わせください。\n
        またのご利用をお待ちしております。
      `,
      purchaseDate: '購入日：{{purchaseDate}}',
      effectiveDate: '有効日：{{validPeriod}}',
      purchaseErr: '購入を失敗しました。',
      refundErr: 'キャンセルを失敗しました。'
    },
    receipt: {
      name: '領収書',
      downloadedName: 'invoice',
      title: '領収書発行',
      content: '領収書内容',
      formTitle: '領収書記載情報',
      formMonth: '「休日メトロ放題」の利用月',
      formReceipient: '宛名',
      formInfo: '備考欄',
      buttonTitle: '領収書を発行する',
      successMessage: '発行された領収書はダウンロードされました',
      failedMessage: '領収書の発行時にエラーが発生しました',
      formMessage:
        '領収書の発行は一つの予約に対して一度限りとなります。二回目以降の発行の際には再発行であることが明記されます。発行した領収書を印刷し、経理精算等にお使いいただけるかどうかはお客様ご自身で予めご確認いただきますようお願いいたします。'
    }
  }
};
