import { action, computed, makeObservable, observable } from 'mobx';
import { OrderApi, PaymentApi } from '@/services';
import { currentMonth, nextMonth, parseDate } from '@/utils';
import {
  GetOrderInfoApiResp,
  GetUserOrdersApiResp,
  StartPaymentApiResp,
  GetUserOrderHistoriesApiResp
} from '@/services/types';

const ORDER_DURATION = 'duration';
const storage = sessionStorage;

export class Order {
  @observable orders: GetUserOrdersApiResp = {};
  @observable purchaseOrder: StartPaymentApiResp = {};
  @observable orderInfo: GetOrderInfoApiResp = {};
  @observable orderDuration = storage.getItem(ORDER_DURATION) || '';
  @observable orderHistories: GetUserOrderHistoriesApiResp = {};

  constructor() {
    makeObservable(this);
  }

  @action
  setOrders = (orders: GetUserOrdersApiResp) => {
    this.orders = orders;
  };

  @action
  setPurchaseOrder = (purchaseOrder: StartPaymentApiResp) => {
    this.purchaseOrder = purchaseOrder;
  };

  @action
  setOrderDuration = (duration: string) => {
    try {
      storage.setItem(ORDER_DURATION, duration);
    } finally {
      this.orderDuration = duration;
    }
  };

  @action
  setOrderInfo = (orderInfo: GetOrderInfoApiResp) => {
    this.orderInfo = orderInfo;
  };

  @action
  setOrderHistories = (orderHistories: GetUserOrderHistoriesApiResp) => {
    this.orderHistories = orderHistories;
  };

  @action
  getPurchaseOrder = async () => {
    if (!this.purchaseOrder?.orderId) {
      const paymentResp = await PaymentApi.startPayment({
        body: { duration: this.nextMonthOrderDuration || this.orderDuration }
      });
      if (paymentResp) {
        this.setPurchaseOrder(paymentResp);
      }
    }
  };

  @action
  fetchOrders = async () => {
    const orderResp = await OrderApi.getUserOrders();
    if (orderResp.currentMonthOrder && orderResp.nextMonthOrder) {
      this.setOrders(orderResp);
      this.setOrderDuration(orderResp.nextMonthOrder.duration ?? '');
    }
  };

  @action
  fetchOrderInfo = async () => {
    if (!this.orderInfo?.orderDate) {
      const orderInfo = await OrderApi.getOrderInfo();
      if (orderInfo) {
        this.setOrderInfo(orderInfo);
      }
    }
  };

  @action
  fetchOrderHistories = async () => {
    const historiesResp = await OrderApi.getUserOrderHistories();
    if (historiesResp.orders?.length) {
      this.setOrderHistories(historiesResp);
    }
    return historiesResp;
  };

  @action
  clearOrders = () => {
    this.orders = {};
    this.orderHistories = {};
    try {
      storage.removeItem(ORDER_DURATION);
    } finally {
      this.orderDuration = '';
    }
  };

  @computed
  get currentMonthPurchasedDate() {
    return this.orders?.currentMonthOrder?.orderDate || '';
  }

  @computed
  get currentMonthValid() {
    const validPeriod = `${this.orders?.currentMonthOrder?.startDate} ~ ${this.orders?.currentMonthOrder?.endDate}`;
    return validPeriod;
  }

  @computed
  get getCurrentMonth() {
    return (
      parseDate(
        this.orders?.currentMonthOrder?.startDate as string
      ).getMonth() + 1
    );
  }

  @computed
  get getNextMonth() {
    return (
      parseDate(this.orders?.nextMonthOrder?.startDate as string).getMonth() + 1
    );
  }

  @computed
  get isCurrentMonthUsed() {
    return this.getCurrentMonth === currentMonth;
  }

  @computed
  get nextMonthPurchasedDate() {
    return this.orders?.nextMonthOrder?.orderDate || '';
  }

  @computed
  get nextMonthValid() {
    const validPeriod = `${this.orders?.nextMonthOrder?.startDate} ~ ${this.orders?.nextMonthOrder?.endDate}`;
    return validPeriod;
  }

  @computed
  get isCurrentMonthPurchased() {
    return this.orders?.currentMonthOrder?.status || false;
  }

  @computed
  get isNextMonthPurchased() {
    return this.orders?.nextMonthOrder?.status || false;
  }

  @computed
  get nextMonthOrderDuration() {
    return this.orders?.nextMonthOrder?.duration || '';
  }

  @computed
  get ordersRendered() {
    if (this.isCurrentMonthPurchased) {
      return [
        {
          month: `${currentMonth}`,
          purchased: this.isCurrentMonthPurchased,
          used: this.isCurrentMonthUsed,
          purchaseDate: this.currentMonthPurchasedDate,
          validPeriod: this.currentMonthValid
        },
        {
          month: `${nextMonth}`,
          purchased: this.isNextMonthPurchased,
          used: false,
          purchaseDate: this.nextMonthPurchasedDate,
          validPeriod: this.nextMonthValid
        }
      ];
    } else if (this.orders?.nextMonthOrder?.status !== undefined) {
      return [
        {
          month: `${nextMonth}`,
          purchased: this.isNextMonthPurchased,
          used: false,
          purchaseDate: this.nextMonthPurchasedDate,
          validPeriod: this.nextMonthValid
        }
      ];
    } else {
      return [];
    }
  }
}

export default new Order();
